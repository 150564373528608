<template>
  <div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center"> 
    <div class="container">
      <p class="logo"><a href="/"><img src="/static/img/flowe.png" class="imglogo"></a></p>
      <div>
        <div class="pagetitle">
          <h2 class="text-center hide">You have signed out from Flowe</h2>
          <p class="text-center">You have signed out from Flowe.</p>
        </div>
        <div class="form-group responsepanel text-center">
          <a href="/sign-in" class="btn bt-xs btn-primary">Take me home</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript"></script>

<script>
  export default{
    name:'signoutconfirm',

    mounted() { 
      $('#preloader').addClass('hide')
    }


  }
</script>